<template>
  <div v-if="firstpost" class="d-flex blabs-list h-100 ml-5">
    <ion-card class="blab-card ml-0 mt-1 py-3 px-3 clickable-item">
      <div class="d-flex">
        <img
          v-image
          class="img"
          :src="resizeUpload(get(firstpost.reply_as_char, 'info.cropProfilePicture', '/empty.png'), '80x80')"
        />
        <div class="ml-3 flex-grow-1">
          <div class="title text-black d-flex align-items-center justify-content-start">
            {{ get(firstpost, 'title') }}
          </div>

          <div class="d-flex mt-1">
            <small v-if="!firstpost.reply_as_char">
              <router-link :to="{ name: 'profile', params: { username: get(firstpost, 'user.username') } }"
                >{{ get(firstpost, 'user.username') }}
              </router-link>
            </small>
            <small v-else>
              <router-link
                :to="{ name: 'character-profile-new', params: { slug: get(firstpost.reply_as_char, 'slug') } }"
                >{{ get(firstpost.reply_as_char, 'info.name') }}
              </router-link>
            </small>

            <small class="ml-2">{{ formatTimeAgo(firstpost.created) }}</small>
          </div>
        </div>
      </div>
      <div class="description mt-3 ml-1">{{ truncateText(stripHtmlTags(get(firstpost, 'text', ''))) }}</div>
      <div class="mt-2 d-flex justify-content-between">
        <div class="actions d-flex">
          <a href="#" @click.prevent>
            <Reaction
              type="post"
              :reactions="reactions"
              :user-reaction="userReaction"
              @changed="(reaction) => reactionChanged(reaction.key, firstpost, reaction.isInstant)"
              @click.stop
            >
              <ion-button color="transparent" class="inline-button clickable">
                <div class="d-flex align-items-center justify-content-center">
                  <i
                    class="ti-thumb-up mr-2 icon"
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                  />
                  <span
                    class="reaction-count"
                    :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                    >{{ reactions.total_count || 0 }}</span
                  >
                </div>
              </ion-button>
            </Reaction>
          </a>
        </div>

        <span class="ml-auto mt-1 pb-1 reply">{{ replyCount || 0 }} repl{{ replyCount === 1 ? 'y' : 'ies' }}</span>
      </div>
    </ion-card>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import { Prop } from 'vue-property-decorator';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import { stripHtmlTags } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';

@Options({
  name: 'BlabCardVertical',
  components: { Reaction },
})
export default class BlabCardVertical extends Vue {
  @Prop({ default: () => ({}) }) posts!: any;
  @Prop({ default: () => ({}) }) public sectionTitle!: string;
  @Prop({ required: false }) public seeAllLink!: {};
  @Prop({ default: false }) isReply!: boolean;
  @Prop({ default: () => ({}) }) count!: any;

  public first = first;
  get = get;
  openBlabAsModal = false;
  blabid = '';
  sunshinerepliesCounts = {};
  formatTimeAgo = formatTimeAgo;
  stripHtmlTags = stripHtmlTags;
  truncateText = truncateText;
  resizeUpload = resizeUpload;

  public replyIds: string[] | undefined;
  public blabb: any = {};

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get firstpost() {
    return get(this.posts[0], 'reply');
  }

  public get replyCount() {
    return get(this.count, `${this.firstpost.id}.child_count`);
  }

  public async reactionChanged(reaction: string, firstpost: any[], isInstant = false) {
    const resp = await store.dispatch('BlabsModule/reactBlabReply', { reaction, blab: firstpost, isInstant });

    this.$emit('afterReaction', { reactResp: resp, firstpost });
  }

  public get reactions() {
    const { reaction_counts } = this.firstpost || {};
    return reaction_counts;
  }

  public get userReaction() {
    if (this.firstpost.user_reaction) {
      return this.firstpost.user_reaction;
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 18px
  font-weight: bold
.description
  font-size: 14px

.reply
  font-size: 14px

.icon
  font-size: 16px
  font-weight: bold

.actions
  max-width: 200px
.reaction-count
  font-size: 14px
  font-weight: bold

.blab-card
  overflow: unset !important

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

  .img
    min-width: 50px
    width: 50px !important
    height: 50px !important
    border-radius: 30px
    border: solid gray 0.1px
    object-fit: cover

.blabs-list
  overflow-x: auto
  overflow: unset !important
.blabs-list::-webkit-scrollbar
  display: none
.blabs-list
  -ms-overflow-style: none
  scrollbar-width: none
</style>
